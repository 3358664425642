import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class ConfigurationApiModule extends VuexModule {
  errors = {};
  configuration_info = {};
  general_configuration = {};
  system_configuration = {};
  authentication_configuration = {};
  feature_configuration = {};
  notification_configuration = {};
  social_configuration = {};
  mail_configuration = {};
  asset_configuration = {};

  get getError() {
    return this.errors;
  }
  get getConfigurationInfo() {
    return this.configuration_info;
  }
  get getGeneralConfigurationInfo() {
    return this.general_configuration;
  }
  get getSystemConfigurationInfo() {
    return this.system_configuration;
  }
  get getAuthenticationConfigurationInfo() {
    return this.authentication_configuration;
  }
  get getFeatureConfigurationInfo() {
    return this.feature_configuration;
  }
  get getNotificationConfigurationInfo() {
    return this.notification_configuration;
  }
  get getSocialConfigurationInfo() {
    return this.social_configuration;
  }
  get getMailConfigurationInfo() {
    return this.mail_configuration;
  }
  get getAssetConfigurationInfo() {
    return this.asset_configuration;
  }

  @Mutation
  [Mutations.SET_CONFIGRATION](configuration_info) {
    this.configuration_info = configuration_info;
    for (let index = 0; index < configuration_info.length; index++) {
      if (configuration_info[index].name == "general") {
        this.general_configuration = JSON.parse(
          configuration_info[index].value
        );
      } else if (configuration_info[index].name == "system") {
        this.system_configuration = JSON.parse(configuration_info[index].value);
      } else if (configuration_info[index].name == "authentication") {
        this.authentication_configuration = JSON.parse(
          configuration_info[index].value
        );
      } else if (configuration_info[index].name == "feature") {
        this.feature_configuration = JSON.parse(
          configuration_info[index].value
        );
      } else if (configuration_info[index].name == "notification") {
        this.notification_configuration = JSON.parse(
          configuration_info[index].value
        );
      } else if (configuration_info[index].name == "social") {
        this.social_configuration = JSON.parse(configuration_info[index].value);
      } else if (configuration_info[index].name == "mail") {
        this.mail_configuration = JSON.parse(configuration_info[index].value);
      } else if (configuration_info[index].name == "asset") {
        this.asset_configuration = configuration_info[index].value;
      }
    }
    this.errors = {};
  }

  @Action
  async [Actions.Configuration_Get]() {
    return await ApiService.get("configuration")
      .then(({ data }) => {
        console.log(data);
        this.context.commit(Mutations.SET_CONFIGRATION, data.data);
        return data;
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }
  @Action
  async [Actions.Configuration_Store](playload) {
    return await ApiService.post("configuration", playload)
      .then(({ data }) => {
        this.context.dispatch(Actions.Configuration_Get);
        return data;
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }
}
