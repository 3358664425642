import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class ApiModuleConfigurationApiModule extends VuexModule {
  // errors = {};
  // api_module_configuration_info = {};
  // travelpayouts_configuration = {};

  // get getApiError() {
  //   return this.errors;
  // }
  // get getApiModuleConfigurationInfo() {
  //   return this.api_module_configuration_info;
  // }
  // get getTravelpayoutsConfigurationInfo() {
  //   return this.travelpayouts_configuration;
  // }

  // @Mutation
  // [Mutations.SET_API_CONFIGURATION](api_configuration_info) {
  //   this.api_module_configuration_info = api_configuration_info;
  //   for (let index = 0; index < api_configuration_info.length; index++) {
  //     if (api_configuration_info[index].name == "travelpayouts") {
  //       this.travelpayouts_configuration = JSON.parse(
  //         api_configuration_info[index].value
  //       );
  //       console.log(this.travelpayouts_configuration);
  //     }
  //   }
  //   this.errors = {};
  // }

  @Action
  async [Actions.Api_Configuration_Get](payload) {
    return await ApiService.post("getapimoduleconfiguration", payload)
      .then(({ data }) => {
        console.log(data);
        // this.context.commit(Mutations.SET_API_CONFIGURATION, data.data);
        return data;
      })
      .catch(({ response }) => {
        // this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }

  @Action
  async [Actions.Api_Configuration_Store](playload) {
    return await ApiService.post("apimoduleconfiguration", playload)
      .then(({ data }) => {
        // this.context.dispatch(Actions.Api_getAllConfiguration);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }
  @Action
  async [Actions.Api_getAllConfiguration]() {
    return await ApiService.get("getAllapimoduleconfiguration")
      .then(({ data }) => {
        // this.context.dispatch(Actions.Api_Configuration_Get, data.data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }
  @Action
  async [Actions.Api_Configuration_update](playload) {
    return await ApiService.put("updateModuleconfiguration", playload)
      .then(({ data }) => {
        // this.context.dispatch(Actions.Api_getAllConfiguration);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }
  @Action
  async [Actions.StoreImage_Configuration](playload) {
    return await ApiService.post("storeImage", playload)
      .then(({ data }) => {
        this.context.dispatch(Actions.Configuration_Get);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }
}
