import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import "maz-ui/css/main.css";
import "@/core/plugins/prismjs";
import "bootstrap";
import VueScrollTo from "vue-scrollto";
import VueProgressBar from "@aacassandra/vue3-progressbar";
import SimpleTypeahead from "vue3-simple-typeahead";

import { SnackbarService, Vue3Snackbar } from "vue3-snackbar";
import "vue3-snackbar/dist/style.css";

import vue3GoogleLogin from "vue3-google-login";

const app = createApp(App);

app.use(SnackbarService);
app.use(SimpleTypeahead);
app.component("vue3-snackbar", Vue3Snackbar);
app.use(store);
app.use(router);
app.use(ElementPlus);
const options = {
  color: "#fccb3ebd",
  failedColor: "#ef5454",
  thickness: "5px",
  //   transition: {
  //     speed: "0.2s",
  //     opacity: "0.6s",
  //     termination: 300,
  //   },
  //   autoRevert: true,
  //   location: "left",
  //   inverse: false,
  autoFinish: false,
};
app.use(vue3GoogleLogin, {
  clientId:
    "288221426418-2lpou9fhvt9tp4lacpq4g38tkn21nf0u.apps.googleusercontent.com",
});
app.use(VueScrollTo);
app.use(VueProgressBar, options);
ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount("#app");
