import { createStore } from "vuex";
import { config } from "vuex-module-decorators";
import createPersistedState from "vuex-persistedstate";
import AuthModule from "@/store/modules/AuthModule";
import AuthinfoModule from "@/store/modules/AuthinfoModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import ThemeModeModule from "@/store/modules/ThemeModeModule";
import ApiModule from "@/store/modules/ApiModule";
import BookingEngine from "@/store/modules/BookingEngine";
import FlightBookingInfo from "@/store/modules/FlightBookingInfo";
import ConfigurationApiModule from "./modules/ConfigurationApiModule";
import ApiModuleConfigurationApiModule from "./modules/ApiModuleConfigurationApiModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    ThemeModeModule,
    AuthinfoModule,
    ApiModule,
    BookingEngine,
    FlightBookingInfo,
    ConfigurationApiModule,
    ApiModuleConfigurationApiModule,
  },
  plugins: [createPersistedState()],
});

export default store;
