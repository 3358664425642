import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import user from "@/core/type/user";
import permission from "@/core/type/permission";
import { IRole } from "@/core/data/roles";
import JwtService from "@/core/services/JwtService";

// export interface user {
//   id: string;
//   user_id: string;
//   avatar: string | null;
//   company: string;
//   phone: string;
//   website: string;
//   country: string;
//   language: string;
//   timezone: string;
//   currency: string;
//   communication: string;
//   surname: string;
//   marketing: string;
//   created_at: string;
//   updated_at: string;
// }

export interface ApiData {
  errors: unknown;
  user_list: Array<user>;
  user: user;
  permission: permission;
  permissions: Array<permission>;
}

@Module
export default class ApiModule extends VuexModule implements ApiData {
  errors = {};
  user = {} as user;
  user_list = {} as Array<user>;
  permission = {} as permission;
  permissions = {} as Array<permission>;
  permission_group = {} as Array<permission>;
  role = {} as IRole;
  roleinfo = {};
  roles = {} as Array<IRole>;

  get getRole(): IRole {
    return this.role;
  }
  get getRoleInfo() {
    return this.roleinfo;
  }
  get getRoleList(): Array<IRole> {
    return this.roles;
  }
  get getRoleError() {
    return this.errors;
  }
  get getUserList(): Array<user> {
    return this.user_list;
  }
  get getUser(): user {
    return this.user;
  }
  get getUserError() {
    return this.errors;
  }

  get getPermission(): permission {
    return this.permission;
  }
  get getPermissionList(): Array<permission> {
    return this.permissions;
  }
  get getPermissionGroupList(): Array<permission> {
    return this.permission_group;
  }

  // @Mutation
  // [Mutations.SET_ERROR](error) {
  //   this.errors = { ...error };
  // }

  @Mutation
  [Mutations.SET_USER_LIST](user_list) {
    this.user_list = user_list;
    this.errors = {};
  }
  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
    this.errors = {};
  }
  @Mutation
  [Mutations.SET_PERMISSION](permissions) {
    this.permissions = permissions;
    this.errors = {};
  }
  @Mutation
  [Mutations.SET_PERMISSION_GROUP](permission_group) {
    this.permission_group = permission_group;
    this.errors = {};
  }

  @Action
  [Actions.USER_LIST]() {
    return ApiService.get("user")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_USER_LIST, data.data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }
  @Action
  [Actions.USER_GET](id) {
    return ApiService.get("user/" + id)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_USER, data.data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }
  @Action
  [Actions.USER_DELETE](id) {
    return ApiService.delete("user/" + id)
      .then(({ data }) => {
        console.log(data);
        this.context.dispatch(Actions.USER_LIST);
        return data;
      })
      .catch(({ response }) => {
        return response;
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.USER_POST](payload) {
    return ApiService.post("user", payload)
      .then(({ data }) => {
        this.context.dispatch(Actions.USER_LIST);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }
  @Action
  [Actions.USER_UPDATE](payload) {
    return ApiService.put("user/" + payload.id, payload.user)
      .then(({ data }) => {
        this.context.dispatch(Actions.USER_LIST);
        this.context.commit(Mutations.SET_USER, data.data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }
  /**
   * Api for  permission
   */
  @Action
  [Actions.GET_PERMISSION]() {
    return ApiService.get("permission")
      .then(({ data }) => {
        // console.log(data.data.permission_group);
        this.context.commit(Mutations.SET_PERMISSION, data.data.permission);
        this.context.commit(
          Mutations.SET_PERMISSION_GROUP,
          data.data.permission_group
        );
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }
  @Action
  [Actions.POST_PERMISSION](payload) {
    return ApiService.post("permission", payload)
      .then(({ data }) => {
        console.log(data);
        this.context.dispatch(Actions.GET_PERMISSION);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }
  @Action
  [Actions.DELETE_PERMISSION](id) {
    return ApiService.delete("permission/" + id)
      .then(({ data }) => {
        this.context.dispatch(Actions.GET_PERMISSION);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }

  /**
   * Role modules
   */
  @Mutation
  [Mutations.SET_ROLE_LIST](roles) {
    this.roles = roles;
    this.errors = {};
  }
  @Mutation
  [Mutations.SET_ROLE](roleinfo) {
    this.roleinfo = roleinfo;
    // console.log(this.roleinfo);
    this.errors = {};
  }
  @Action
  [Actions.ROLE_LIST]() {
    return ApiService.get("role")
      .then(({ data }) => {
        console.log(data.data);
        this.context.commit(Mutations.SET_ROLE_LIST, data.data.role);
        this.context.commit(Mutations.SET_PERMISSION, data.data.permissions);
        this.context.dispatch(Actions.GET_PERMISSION);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }
  @Action
  [Actions.POST_ROLE](payload) {
    return ApiService.post("role", payload)
      .then(({ data }) => {
        this.context.dispatch(Actions.VERIFY_AUTH, {
          api_token: JwtService.getToken(),
        });
        this.context.dispatch(Actions.ROLE_LIST);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }
  @Action
  [Actions.PUT_ROLE](payload) {
    return ApiService.put("role/" + payload.id, payload.role)
      .then(({ data }) => {
        console.log(data);
        this.context.dispatch(Actions.VERIFY_AUTH, {
          api_token: JwtService.getToken(),
        });
        this.context.dispatch(Actions.ROLE_LIST);
        this.context.dispatch(Actions.GET_ROLE, payload.id);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }
  @Action
  [Actions.GET_ROLE](id) {
    return ApiService.get("role/" + id)
      .then(({ data }) => {
        console.log(data);
        this.context.commit(Mutations.SET_ROLE, data.data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }
  @Action
  [Actions.DELETE_ROLE](id) {
    return ApiService.delete("role/" + id)
      .then(({ data }) => {
        this.context.dispatch(Actions.VERIFY_AUTH, {
          api_token: JwtService.getToken(),
        });
        this.context.dispatch(Actions.ROLE_LIST);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }
}
