import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { IInvoice } from "@/core/data/invoices";

export interface Data {
  errors: unknown;
  booking_info_list: Array<IInvoice>;
  booking_info: IInvoice;
}
@Module
export default class FlightBookingInfo extends VuexModule implements Data {
  errors = {};
  booking_info = {} as IInvoice;
  booking_info_list = {} as Array<IInvoice>;
  traveler_info_list = {};
  get getFlightBookingInfoList(): Array<IInvoice> {
    return this.booking_info_list;
  }
  get getFlightBookingInfo(): IInvoice {
    return this.booking_info;
  }
  get getFlightTravelerInfoList() {
    return this.traveler_info_list;
  }
  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }
  @Mutation
  [Mutations.SET_Fligth_Booking_Info_List](booking_info) {
    this.booking_info_list = booking_info;
    this.errors = {};
  }
  @Mutation
  [Mutations.SET_Fligth_Booking_Info](booking_info) {
    this.booking_info = booking_info;
    this.errors = {};
  }
  @Action
  async [Actions.Fligth_Booking_Info_List]() {
    return await ApiService.get("flightbookinginfo")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_Fligth_Booking_Info_List, data.data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }
  @Action
  async [Actions.Fligth_Booking_Info](id) {
    return await ApiService.get("flightbookinginfo/" + id)
      .then(({ data }) => {
        // console.log(data.data);
        this.context.commit(Mutations.SET_Fligth_Booking_Info, data.data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }
  @Action
  [Actions.Fligth_Booking_Info_Delete](id) {
    return ApiService.delete("flightbookinginfo/" + id)
      .then(({ data }) => {
        console.log(data);
        this.context.dispatch(Actions.Fligth_Booking_Info_List);
        // this.context.dispatch(Actions.Fligth_Booking_Info_List);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }
  @Action
  [Actions.Fligth_Booking_Info_Cancel](id) {
    return ApiService.get("flightbookinginfo/" + id + "/edit")
      .then(({ data }) => {
        console.log(data);
        this.context.dispatch(Actions.Fligth_Booking_Info_List);
        // this.context.dispatch(Actions.Fligth_Booking_Info_List);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }
  /**
   * Fligth Traveler Info
   */
  @Action
  async [Actions.Fligth_Traveler_Info_List]() {
    return await ApiService.get("flighttraveler")
      .then(({ data }) => {
        console.log(data);
        //  this.context.commit(Mutations.SET_Fligth_Traveler_Info_List, data.data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }
}
