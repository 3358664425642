import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface UserInfo {
  id: string;
  user_id: string;
  avatar: string | null;
  company: string;
  phone: string;
  website: string;
  country: string;
  language: string;
  timezone: string;
  currency: string;
  communication: string;
  surname: string;
  marketing: string;
  created_at: string;
  updated_at: string;
}

export interface AuthInfo {
  errors: unknown;
  info: UserInfo;
}

@Module
export default class AuthinfoModule extends VuexModule implements AuthInfo {
  errors = {};
  info = {} as UserInfo;

  get getUserInfo(): UserInfo {
    return this.info;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_USER_INFO](info) {
    this.info = info;
    this.errors = {};
  }
  @Mutation
  [Mutations.UPDATE_AND_SAVE_USER_INFO](data) {
    this.info = data.info;
    // console.log(data);
  }

  @Action
  async [Actions.USER_INFO]() {
    return await ApiService.get("userinfo")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_USER_INFO, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.UPDATE_USER_INFO](credentials) {
    return ApiService.post("userinfo", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.UPDATE_AND_SAVE_USER_INFO, data);
        this.context.commit(Mutations.SET_USER, data.user);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
}
