import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface UserInfo {
  id: string;
  user_id: string;
  avatar: string | null;
  company: string;
  phone: string;
  website: string;
  country: string;
  language: string;
  timezone: string;
  currency: string;
  communication: string;
  surname: string;
  marketing: string;
  created_at: string;
  updated_at: string;
}

export interface AuthInfo {
  errors: unknown;
}

@Module
export default class BookingEngine extends VuexModule implements AuthInfo {
  errors = {};
  flights = {};
  flight_search_token = {};
  select_flight = {};
  booking_info = {};
  TravelerData = {};

  get getFlights() {
    return this.flights;
  }
  get flightSearchToken() {
    return this.flight_search_token;
  }
  get getSelectedFlight() {
    return this.select_flight;
  }
  get getBookingInfo() {
    return this.booking_info;
  }
  get getTravelerData() {
    return this.TravelerData;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }
  @Mutation
  [Mutations.SET_FLIGHT_SEARCH](flights) {
    this.flights = {};
    this.select_flight = {};
    this.booking_info = {};
    this.TravelerData = {};
    this.flights = flights;
    this.errors = {};
  }
  @Mutation
  [Mutations.SET_FLIGHT_SEARCH_TOKEN](flight_search_token) {
    this.flight_search_token = {};
    this.flight_search_token = flight_search_token;
    this.errors = {};
  }
  @Mutation
  [Mutations.SET_FLIGHT_SELECT](select_flight) {
    this.select_flight = {};
    this.select_flight = select_flight;
    this.errors = {};
  }
  @Mutation
  [Mutations.SET_FLIGHT_CREATE_ORDERS](booking_info) {
    this.booking_info = {};
    this.booking_info = booking_info;
    this.errors = {};
  }
  @Mutation
  [Mutations.SET_FLIGHT_TRAVELER_DATA](TravelerData) {
    this.TravelerData = {};
    this.TravelerData = TravelerData;
    this.errors = {};
  }
  @Action
  async [Actions.FLIGHT_GET_TOKEN](payload) {
    return await ApiService.get("get_flight_Search_token", payload)
      .then(({ data }) => {
        if (data.success) {
          this.context.commit(Mutations.SET_FLIGHT_SEARCH_TOKEN, data.data);
        }

        return data;
      })
      .catch(({ response }) => {
        console.log(response);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }
  @Action
  async [Actions.FLIGHT_SEARCH](payload) {
    return await ApiService.post("flight_Search", payload)
      .then(({ data }) => {
        console.log(data);
        this.context.commit(Mutations.SET_FLIGHT_SEARCH, data);
        return data;
      })
      .catch(({ response }) => {
        console.log(response);
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }
  @Action
  async [Actions.FLIGHT_SELECT](payload) {
    return await ApiService.post("Flight_Select", payload)
      .then(({ data }) => {
        console.log(data);
        if (data.success && !data.warnings) {
          this.context.commit(Mutations.SET_FLIGHT_SELECT, data.data);
        }
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }
  @Action
  async [Actions.FLIGHT_CREATE_ORDERS](payload) {
    return await ApiService.post("Flight_Create_Orders", payload)
      .then(({ data }) => {
        console.log(data);
        if (data.success) {
          this.context.dispatch(Actions.Fligth_Booking_Info_List);
          this.context.dispatch(
            Actions.Fligth_Booking_Info,
            data.data.ticket_id
          );
          this.context.commit(Mutations.SET_FLIGHT_CREATE_ORDERS, data.data);
        }
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }
  @Action
  async [Actions.SEARCH_AIRPORT](payload) {
    return await ApiService.post("Get_Airport", payload)
      .then(({ data }) => {
        // console.log(data);
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
        return response;
      });
  }
}
