import { resolveComponent as _resolveComponent, createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_vue3_snackbar = _resolveComponent("vue3-snackbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_component_vue3_snackbar, {
        top: "",
        bottom: "",
        right: "",
        duration: 4000
      })
    ]))
  ], 64))
}