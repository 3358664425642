enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  SET_THEME_MODE_ACTION = "setThemeModeAction",
  /**
   * USER Actions
   */
  USER_INFO = "userinfo",
  UPDATE_USER_INFO = "updateuserinfo",
  USER_LIST = "userlist",
  USER_DELETE = "userdelete",
  USER_GET = "user_get",
  USER_POST = "user_post",
  USER_UPDATE = "user_update",
  GET_PERMISSION = "get_permission",
  POST_PERMISSION = "post_permission",
  DELETE_PERMISSION = "delete_permission",
  /**
   * ROLE Actions
   */
  ROLE_LIST = "role_list",
  GET_ROLE = "role_get",
  POST_ROLE = "post_role",
  DELETE_ROLE = "delete_role",
  PUT_ROLE = "put_role",
  /**
   * Booking Engine action
   */
  FLIGHT_GET_TOKEN = "flight_get_token",
  FLIGHT_SEARCH = "flights_search",
  FLIGHT_SELECT = "flights_Select",
  FLIGHT_CREATE_ORDERS = "flights_create_orders",
  SEARCH_AIRPORT = "search_airport",
  /**
   * FligthBookingInfo action
   */
  Fligth_Booking_Info_List = "fligth_booking_info_list",
  Fligth_Booking_Info = "fligth_booking_info",
  Fligth_Booking_Info_Delete = "fligth_booking_info_delete",
  Fligth_Booking_Info_Cancel = "fligth_booking_info_cancel",
  /**
   * FligthTravelerInfo action
   */
  Fligth_Traveler_Info_List = "fligth_traveler_info_list",
  /**
   * Configuration action
   */
  Configuration_Get = "get_configuration",
  Configuration_Store = "configuration_store",
  /**
   * Api Configuration action
   */
  Api_Configuration_Get = "api_configuration_get",
  Api_Configuration_Store = "api_configuration_store",
  Api_Configuration_update = "api_configuration_update",
  Api_getAllConfiguration = "api_getallconfiguration",
  StoreImage_Configuration = "store_image_configuration",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_THEME_MODE_MUTATION = "setThemeModeMutation",
  /**
   * User Mutations
   */
  SET_USER_INFO = "setuserinfo",
  UPDATE_AND_SAVE_USER_INFO = "updateandsaveinfo",
  SET_USER_LIST = "setuserlist",
  UPDATE_USER_DELETE = "update_user_delete",
  SET_USER_GET = "set_user_get",
  SET_PERMISSION = "set_permission",
  SET_PERMISSION_GROUP = "set_permission_group",
  SET_ROLE_OR_PERMISSIONS = "set_role_or_permission",
  /**
   * ROLE Mutations
   */
  SET_FLIGHT_SEARCH_TOKEN = "set_flight_search_token",
  SET_ROLE_LIST = "set_role_list",
  SET_ROLE = "set_role",
  SET_FLIGHT_SEARCH = "set_flights_search",
  SET_FLIGHT_SELECT = "set_flights_Select",
  SET_FLIGHT_CREATE_ORDERS = "set_flights_create_orders",
  SET_FLIGHT_TRAVELER_DATA = "set_flight_traveler_data",
  /**
   * FligthBookingInfo Mutations
   */
  SET_Fligth_Booking_Info_List = "set_fligth_booking_info_list",
  SET_Fligth_Booking_Info = "set_fligth_booking_info",
  /**
   * Fligth Traveler Info Mutations
   */
  SET_Fligth_Traveler_Info_List = "set_fligth_traveler_info_list",
  /**
   * Configurations Mutations
   */
  SET_CONFIGRATION = "set_configuration",
  /**
   * Api Configuration Mutations
   */
  SET_API_CONFIGURATION = "set_api_configuration",
}

export { Actions, Mutations };
